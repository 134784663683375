import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_statistic = _resolveComponent("a-statistic")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_cascader, {
      "change-on-select": "",
      value: $setup.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      options: $setup.options,
      placeholder: "请选择",
      onChange: $setup.handleChange
    }, null, 8, ["value", "options", "onChange"]),
    _createVNode(_component_a_row, {
      type: "flex",
      class: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { flex: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_statistic, {
              title: "处理工单量",
              value: $setup.allGd
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_statistic, {
              title: "完成率",
              precision: 2,
              value: $setup.complete
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_statistic, {
              title: "超时率",
              precision: 2,
              value: $setup.overtime
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_statistic, {
              title: "未完成率",
              precision: 2,
              value: $setup.undone
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}