
    import {defineComponent, ref, onMounted} from 'vue';
    import {getroleAndMember, analyze} from '@/network/ship';

    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }

    export default {
        name: "CommonAllAnalyze",
        setup() {
            const options = ref([]);
            const value = ref<string[]>([]);
            const allGd = ref<number>(0);
            const complete = ref(0);
            const overtime = ref(0);
            const undone = ref(0);

            const roleAndMember = () => {
                getroleAndMember().then((response) => {
                    if (response.status == 'done') {
                        options.value = response.roleAndMember;
                    }
                })
            }
            const getAnalyze = (value) => {
                //console.log(value.value);
                analyze({'id': value}).then((response) => {
                    //console.log(response);
                    allGd.value = response.all.allGd;
                    complete.value = (response.percent[2].complete)/(response.all.allGd);
                    overtime.value = (response.percent[1].overtime)/(response.all.allGd);
                    undone.value = (response.percent[0].undone)/(response.all.allGd);
                })
            }
            const handleChange = (value, selectedOptions) => {
                /*console.log(value);
                console.log(selectedOptions);*/
                getAnalyze(value);
            }
            onMounted(() => {
                roleAndMember();
            })
            return {
                value,
                options,
                handleChange,
                allGd,
                undone,
                overtime,
                complete,
            };
        }
    }
